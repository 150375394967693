<template>
  <div>
    <div class='date-label'>
      <div v-if='dateLabel'>
        {{ this.dateLabel }}
      </div>
      <div v-if='startDateLabel'>
        {{ this.startDateLabel }}
      </div>
      <div v-if='endDateLabel'>
        {{ this.endDateLabel }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    attribute: Object, // This prop will get passed in by `v-date-picker`
  },
  computed: {
    date() {
      return this.attribute.targetDate;
    },
    isDate() {
      return this.date.isDate;
    },
    isRange() {
      return this.date.isRange;
    },
    days() {
      return this.date.daySpan + 1;
    },
    nights() {
      return this.date.daySpan;
    },
    dateLabel() {
      if (!this.date || !this.date.date) return '';
      return this.getDateString(this.date.date);
    },
    startDateLabel() {
      if (!this.date || !this.date.start) return '';
      return this.getDateString(this.date.start);
    },
    endDateLabel() {
      if (!this.date || !this.date.end) return '';
      return this.getDateString(this.date.end);
    },
  },
  methods: {
    getDateString(date) {
      const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
      return date.toLocaleDateString(window.navigator.userLanguage || window.navigator.language, options);
    },
  },
};
</script>

