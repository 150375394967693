
<script>
import Vue from 'vue';
import axios from 'axios';
import Fuse from 'fuse.js';
import moment from 'moment';
import VCalendar from 'v-calendar';
import 'v-calendar/lib/v-calendar.min.css';
import DatePickerPopover from './components/DatePickerPopover.vue';

Vue.prototype.$http = axios;

var filter = function(text, length, clamp){
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);

Vue.filter('formatTime', function(value) {
    if (value) {
      return moment.unix(value).format('H.mm')
    }
});

Vue.filter('formatDate', function(value) {
    if (value) {
      return moment.unix(value).format('L')
    }
});

Vue.use(VCalendar, {
  firstDayOfWeek: 2
});

// UUID
const uuidv4 = require('uuid/v4');

// Create our Vue component


export default {
    name: 'schedule',
    props: [
        'craftData',
        'uid'
    ],
    data () {
        return {
            selectedValue: { weekdays: 0 },
            siteUrl: "",
            searchTerm: "",
            toggleFilterState: false,
            items: [],
            events: [],
            eventInstances: [],
            dateInstances: [],
            lang: "",
            selectView: "schedule",
            loaded: false,
            isToggleOn: false,
            source: "",
            archive: false
        }
    },

    mounted: function mounted() {
        var _this = this;
        var siteUrl = _this.craftData.siteurl;

        var instance = axios.create({
            baseURL: '',
            headers: { 'X-Custom-Header': 'foobar' }
        });

        instance.interceptors.request.use(function (config) {
            return config;
        });

        // before a response is returned stop nprogress
        instance.interceptors.response.use(function (response) {
            _this.loaded = true;
            return response;
        });
        
        // GET LOCATIONS
        instance.get( siteUrl + '/api/locations.json' ).then(function (response) {

            _this.locations = response.data.data;

        }).catch(function (error) {

            _this.errored = true;
        }).finally(function () {
            return _this.loading = false;
        });

        // GET EVENTS
        instance.get( siteUrl + '/api/schedule.json' ).then(function (response) {
            // SAVE RESPONSE
            _this.items = response.data.data;
            var locale = _this.items[0].lang;
            // SET LOCALE
            moment.locale(locale);

            // 
            var result = [];
            var k = 0;
            for(var i=0; i<_this.items.length; i++){
                for(var j=0; j<_this.items[i].events.length; j++){
                        var id = _this.items[i].events[j].id;
                        var startTime = _this.items[i].events[j].startTime;
                        var endTime = _this.items[i].events[j].endTime;
                        result.push({id, startTime, endTime});
                        k++;
                }                
            }  

            _this.events = result;


            var events = _this.events;
            var instances = _this.eventInstances;
            var $var  = {};
            var counter = 0;
            var dateArray = [];


            // CREATE EVENT INSTANCES
            for (var i = 0, len = _this.items.length; i < len; i++) {

                var category = _this.items[i].category;
                var itemId = _this.items[i].id;
                console.log(i)
 
                if(_this.items[i].events.length > 0 ) {
     
                    for (var j = 0, leng = _this.items[i].events.length; j < leng; j++) {
                               
                        // CREATE UUIDS
                        var uuid = uuidv4();
                        var dateItem = _this.items[i].events[j].startTime;
                        var day = new Date(_this.items[i].events[j].startTime * 1000);
                        day.setHours(0,0,0,0); // Set Event Day to Midnight

                        console.log(day);
                        // CALCULATE DISTANCE IN DAYS TO CURRENT DATE
                        var dateNow = Math.round(new Date() / 1000);
                        // get total seconds between the times
                        var delta = dateItem - dateNow;
                        // calculate (and subtract) whole days
                        var days = Math.floor(delta / 86400);
                        

                        if( days == 0 ) {
                            var isPast = false;
                            var isCurrent = true;
                            var isFuture = false;
                        } else if ( days < 0 ) {
                            var isPast = true;
                            var isCurrent = false;
                            var isFuture = false;
                        } else if ( days > 0 ) {
                            var isPast = false;
                            var isCurrent = false;
                            var isFuture = true;
                        }
  
                        instances.push({ 
                                    active: false,
                                    timestamp: dateItem,
                                    cat: category,
                                    day: day,
                                    uid: uuid,
                                    projectId: _this.items[i].events[j].projectId, 
                                    startTime: _this.items[i].events[j].startTime, 
                                    endTime: _this.items[i].events[j].endTime,
                                    fullDay: _this.items[i].events[j].fullDay,
                                    lang: _this.items[i].lang,
                                    location: _this.items[i].events[j].location,
                                    title: _this.items[i].title,
                                    image: _this.items[i].image,
                                    text: _this.items[i].body,
                                    searchText: _this.items[i].bodyNoTags,
                                    teaser: _this.items[i].teaser,
                                    venue: _this.items[i].titelVeranstaltungsort,
                                    city: _this.items[i].ort,
                                    isPast: isPast,
                                    isCurrent: isCurrent,
                                    isFuture: isFuture,
                                    url: _this.items[i].url 
                        });



                        instances.sort(function(a,b){
 /**/
                            return a.startTime - b.startTime;
                        });

                        var cat1Counter = 0;
                        var cat2Counter = 0;

                        for (var k = 0; k < instances.length; k++) {
                            if ( instances[k].cat == "Akzent") {
                                cat1Counter++;
                                var catCounter = cat1Counter;
                                instances[k].catCounter = catCounter;
                            } else if ( instances[k].cat == "Debatte" ) {
                                cat2Counter++;
                                var catCounter = cat2Counter;
                                instances[k].catCounter = catCounter;                             
                            }
                             } 
                        }
                                       

                    }


            }


        }).catch(function (error) {

            _this.errored = true;
        }).finally(function () {

            return _this.loading = false;

            
        });
            //this.scrollToClosestDate();

    },

    methods: {

    toggle: function(uid) {
        var i = this.eventInstances.findIndex(item => item.uid === uid);
        this.eventInstances[i].active = !this.eventInstances[i].active;

    },

        // Pre-render pages when the user mouses over a link
        // Usage: <a href="" @mouseover="prerenderLink">
    prerenderLink: function(e) {
            var head = document.getElementsByTagName("head")[0];
            var refs = head.childNodes;
            var ref = refs[ refs.length - 1];

            var elements = head.getElementsByTagName("link");
            Array.prototype.forEach.call(elements, function(el, i) {
                if (("rel" in el) && (el.rel === "prerender"))
                el.parentNode.removeChild(el);
            }); 

            var prerenderTag = document.createElement("link");
            prerenderTag.rel = "prerender";
            prerenderTag.href = e.currentTarget.href;
            ref.parentNode.insertBefore(prerenderTag,  ref);
        },

        getDetails: function (i, target) {

            var details = new Object();
            var details = target.find(item => item.uid === i);

            return details
        },

        resetFilter() {
            this.selectedValue = { weekdays: 0 };
        },


        toggleFilter() {
            this.toggleFilterState = !this.toggleFilterState;
        },
        
        toggleArchive() {
            this.archive = !this.archive;
        },
        
         checkDate(d) {
            var date = new Date(),
            date = d;
            var diff = null;

            diff = moment().diff(d, 'days');

            console.log(diff);

            if( diff > 0 ) {
                return "past";
            } else if (diff == 0 ) {
                return "today";
            } else if (diff < 0 ) {
                return "future";
            }

    	}
    },

    computed: {

            attributes() {
                
            return [
                {
                contentStyle: {
                    fontWeight: '700',
                    fontSize: '.9rem',
                },
                dates: new Date(),
                },
                ...this.eventInstances.map(instance => ({
                dates: new Date(instance.startTime * 1000),
                highlight: {
                    backgroundColor: "red",
                    opacity: instance.isPast ? 0.3 : 1,
                },
                popover: {
                    label: instance.title,
                    component: DatePickerPopover,
                    hideIndicator: true // Don't want to show the indicator
                },
                })),
            ];
            },


        timeNow: function timeNow() {
            var timeNow = Date.now();

            return timeNow;
            },

        dateFilter: function dateFilter() {
            var source = this.eventInstances,
                vm = this;

            return source.filter(function (source) {
                var s = source.timestamp*1000;
                var t = Math.floor(vm.selectedValue.start);
                var q = Math.floor(vm.selectedValue.end);

                if ( vm.selectedValue.weekdays != 0) {

                    return s >= t && s <= q;
                } else {

                    return source;
                }
                
            });
        },

        filteredResults: function filteredResults( ) {
            if( this.selectView != "schedule") {
                var source = this.items;


            } else {
                var source = this.eventInstances;

            }
            
           
            var vm = this,
                category = "All";
           /*
            if (category === "All") {
                return source;
            } else {
            */
        
            // DATEFILTER
            //}
    
            return source.filter(function (source) {
                var s = source.startTime;
                var t = Date.parse(vm.selectedValue.start)/1000;
                var q = Date.parse(vm.selectedValue.end)/1000;

                if ( vm.selectedValue.weekdays != 0) {

                    return s > t && s < q;
                } else {

                    return source;
                }
            });
        },

        searchResults: function searchResults() {
            var options = {
                shouldSort: true,
                threshold: 0.1,
                location: 0,
                distance: 10000,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: ["title", "category", "bodyNoTags", "lead"]
            };

            var keyword = this.searchTerm;
            var fuse = new Fuse(this.filteredResults, options);
            var result = fuse.search(keyword);

            if (keyword) {

                return result;
            } else {
                return this.filteredResults;
            }
        },

        sortedArray: function() {
            function compare(a, b) {
              if (a.startTime < b.startTime)
                return -1;
              if (a.startTime > b.startTime)
                return 1;
              return 0;
            }
            return this.eventInstances.sort(compare);

          },
          
        upcomingEvents: function() {
            const events = this.eventInstances
            const t = this.timeNow / 1000
            const upcoming = events.filter(event => event.startTime >= t)
            return upcoming
        },

        // Get the three upcoming events
        // TODO: Get FOUR events and hide the fourth w/ breaking points and CSS
        upcomingPreview: function() {
            const events = this.upcomingEvents
            const preview = events.slice(0,3)
            return preview
        },


        
    }

}

</script>

