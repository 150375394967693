// import styles from '../scss/app.scss';
import lazysizes from 'lazysizes';
import Schedule from '../vue/Schedule.vue';


// App main
const main = async () => {
    // Import our CSS
    const Styles = await import(/* webpackChunkName: "styles" */ '../scss/app.scss');
    // Async load the vue module
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue');
    // Create our vue instance
    const vm = new Vue.default({
        el: "#app",
        delimiters: ['[%', '%]'],
        components: {
            Schedule
        },
        data: {
        },
        methods: {
        },
        mounted: function() {
            
        },
    });



};
// Execute async function
main().then( (value) => {
    
});

$("#navToggle").on('click', function(event){
	$("#nav-mobile").toggleClass("active"); 
	$(this).toggleClass("is-active");
	$('#container').toggleClass("hidden"); 
	$('.homeimages').toggleClass("hidden"); 
	$('footer').toggleClass("hidden");
}
);
$('#content').on('click', function(event){
	$('#navToggle').removeClass("is-active");
	$('#nav-mobile').removeClass("active");
	$('#container').show();
	$('.homeimages').show();
	$('footer').show();
});